import { jsx as _jsx } from "react/jsx-runtime";
import ReactDOM from 'react-dom/client';
import MainButton from '@ludo.ninja/ui/build/system/Buttons/MainButton';
var LoginButton = function (_a) {
    var url = _a.url;
    return (_jsx(MainButton, { onClick: function () { return window.open(url, '_self'); }, variant: "primaryM", text: 'Go to App' }));
};
var MintButton = function (_a) {
    var url = _a.url;
    return (_jsx(MainButton, { onClick: function () { return window.open(url, '_self'); }, variant: "primaryL", text: 'Mint Your Reputation' }));
};
var ApiButton = function (_a) {
    var url = _a.url;
    return (_jsx(MainButton, { onClick: function () { return window.open(url, '_blank'); }, variant: "primaryL", text: 'Check Our API' }));
};
var LudoCuratorButton = function (_a) {
    var url = _a.url;
    return (_jsx(MainButton, { onClick: function () { return window.open(url, '_self'); }, variant: "primaryL", text: 'Become a Ludo Curator' }));
};
export var initializeMainButtonReact = function (_a) {
    var url = _a.url;
    var rootElement = document.getElementById('joinLudoBtn');
    var root = ReactDOM.createRoot(rootElement);
    root.render(_jsx(LoginButton, { url: url }));
};
export var initializeButtonReact = function (_a) {
    var id = _a.id, url = _a.url, label = _a.label, _b = _a.openInNewTab, openInNewTab = _b === void 0 ? true : _b;
    var rootElement = document.getElementById(id);
    if (rootElement) {
        var root = ReactDOM.createRoot(rootElement);
        root.render(_jsx(MainButton, { onClick: function () { return window.open(url, openInNewTab ? '_blank' : '_self'); }, variant: "primaryL", text: label }));
    }
};
export var initializeMintButtonReact = function (_a) {
    var url = _a.url;
    var rootElement = document.getElementById('mintLudoBtn');
    var root = ReactDOM.createRoot(rootElement);
    root.render(_jsx(MintButton, { url: url }));
};
export var initializeApiButtonReact = function (_a) {
    var url = _a.url;
    var rootElement = document.getElementById('apiBtn');
    var root = ReactDOM.createRoot(rootElement);
    root.render(_jsx(ApiButton, { url: url }));
};
export var initializeLudoCuratorButtonReact = function (_a) {
    var url = _a.url;
    var rootElement = document.getElementById('ludoCuratorBtn');
    var root = ReactDOM.createRoot(rootElement);
    root.render(_jsx(LudoCuratorButton, { url: url }));
};
