import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ReactDOM from 'react-dom/client';
import { Sidebar } from '@ludo.ninja/ui/build/components/sidebar';
import { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { lightTheme } from '@ludo.ninja/ui/build/system/theme';
import process from 'process';
import { ludoDomains } from "@ludo.ninja/core/build/ludoDomains";
import { authCookies } from "@ludo.ninja/api";
var Burger = function () {
    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var closeSidebar = function () {
        setIsOpen(false);
    };
    useEffect(function () {
        var toggleButton = document.getElementById('header_mobileToggle');
        var toggleIsOpen = function () {
            setIsOpen(function (prev) { return !prev; });
        };
        if (toggleButton) {
            toggleButton.addEventListener('click', toggleIsOpen);
        }
        return function () {
            if (toggleButton) {
                toggleButton.removeEventListener('click', toggleIsOpen);
            }
        };
    }, []);
    var nftMintingUrl = "".concat(ludoDomains[process.env.ENV_VALUE]['app'], "/minting");
    return (_jsx(ThemeProvider, { theme: lightTheme, children: _jsx(Sidebar, { textLoginButton: 'Go to App', slots: {
                menu: [
                    _jsx("a", { id: "ludoNftLink", className: "button link get-extension", target: "_self", href: nftMintingUrl, children: "Ludo NFT" }),
                    _jsxs("a", { className: "header_links--link", href: "https://chromewebstore.google.com/detail/ludo-rank/fgcmebogmgdheonhhefnblfodobpmfip?pli=1", target: "_blank", children: [_jsx("img", { className: "", src: "/public/images/landing/chrome.svg", loading: "eager", alt: "chrome svg", height: "18px", width: "18px" }), "Get Chrome Extension"] }),
                    _jsx("a", { className: "header_links--link", href: "https://apidocs.ludo.com/", target: "_blank", children: "Docs" }),
                ],
                userPicture: function () { return null; },
            }, isOpen: isOpen, closeSidebar: closeSidebar, isAuthorized: false, handle: {
                login: function () {
                    var url;
                    var _a = authCookies.getCookies(), userId = _a.userId, wallets = _a.wallets, newUser = _a.newUser, inviteCode = _a.inviteCode, authToken = _a.authToken, refreshToken = _a.refreshToken, role = _a.role;
                    if (authToken &&
                        refreshToken &&
                        userId &&
                        wallets &&
                        newUser &&
                        inviteCode &&
                        role) {
                        url = "".concat(ludoDomains[process.env.ENV_VALUE]['profile'], "/").concat(userId, "/nfts");
                    }
                    else {
                        url = "".concat(ludoDomains[process.env.ENV_VALUE]['auth'], "/login");
                    }
                    window.open(url, '_self');
                },
                logout: function () { },
            } }) }));
};
export var initializeBurgerReact = function () {
    var rootElement = document.getElementById('burger');
    var root = ReactDOM.createRoot(rootElement);
    root.render(_jsx(Burger, {}));
};
