var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import './styles/index.scss';
import { initializeBurgerReact } from './burgerInit';
import { initializeLudoCuratorButtonReact, initializeMintButtonReact, } from './mainButtonInit';
// import markerSDK from '@marker.io/browser';
import { initializeEnvTitleReact } from './envLineInit';
import process from 'process';
import { authCookies } from '@ludo.ninja/api';
import { ludoDomains } from '@ludo.ninja/core/build/ludoDomains';
import { initializeAlertReact } from './initializeAlert';
import { CountUp } from 'countup.js';
var ANIMATION_PREFIX = 'ludo-animation_';
var observer = new IntersectionObserver(function (entries) {
    entries.forEach(function (entry) {
        if (entry.isIntersecting) {
            entry.target.classList.forEach(function (cls) {
                if (cls.startsWith(ANIMATION_PREFIX)) {
                    // Replace the class with the new one without the prefix
                    entry.target.classList.replace(cls, cls.replace(ANIMATION_PREFIX, ''));
                }
            });
            entry.target.classList.add('animated');
            return;
        }
    });
});
var COUNT_UP_PREFIX = 'countUp';
var countUpObserver = new IntersectionObserver(function (entries) {
    entries.forEach(function (entry) {
        if (entry.isIntersecting && !entry.target.classList.contains('countUpAnimated')) {
            switch (entry.target.id) {
                case 'countUpBlockchains':
                    var countUpBlockchains = new CountUp('countUpBlockchains', 18, { duration: 3 });
                    countUpBlockchains.start();
                    break;
                case 'countUpAssets':
                    var countUpAssets = new CountUp('countUpAssets', 200, { duration: 3, suffix: 'M+' });
                    countUpAssets.start();
                    break;
                case 'countUpPartners':
                    var countUpPartners = new CountUp('countUpPartners', 35, { duration: 3, suffix: '+' });
                    countUpPartners.start();
                    break;
                default:
                    break;
            }
            entry.target.classList.add('countUpAnimated');
            return;
        }
    });
}, { threshold: 0.5 });
var frameCount = 231;
var currentFrame = function (index) { return ("public/video/frames/LR-NFT-".concat(index.toString().padStart(3, '0'), ".png")); };
var imageUrls = [];
for (var i = 0; i < frameCount; i++) {
    imageUrls[i] = currentFrame(i);
}
function preloadImagesSequential(index) {
    if (index === void 0) { index = 0; }
    var images = [];
    if (frameCount > index) {
        images[index] = new Image();
        images[index].onload = function () {
            preloadImagesSequential(index + 1);
        };
        images[index].src = currentFrame(index);
    }
}
var preloadImages = function () {
    var images = [];
    for (var i = 0; i < frameCount; i++) {
        images[i] = new Image();
        images[i].src = currentFrame(i);
    }
};
var preloadImagesPromise = function (urls) {
    var promises = urls.map(function (url) {
        return new Promise(function (resolve, reject) {
            var image = new Image();
            image.src = url;
            image.onload = function () { return resolve(image); };
            image.onerror = function () { return reject(url); };
        });
    });
    return Promise.allSettled(promises);
};
var initSlider = function (sliderId, minWidth, maxWidth) {
    if (minWidth === void 0) { minWidth = 15; }
    if (maxWidth === void 0) { maxWidth = 85; }
    var slider = document.querySelector("#".concat(sliderId, " input[type=\"range\"]"));
    var foregroundImage = document.querySelector("#".concat(sliderId, " .foreground-img"));
    var sliderButton = document.querySelector("#".concat(sliderId, " .slider-button"));
    if (slider) {
        var lastSliderPos_1 = 40;
        slider.addEventListener('input', function (event) {
            // @ts-ignore
            var sliderPos = +event.target.value;
            if (sliderPos >= minWidth && sliderPos <= maxWidth) {
                lastSliderPos_1 = sliderPos;
                foregroundImage.style.width = "".concat(sliderPos, "%");
                sliderButton.style.left = "calc(".concat(sliderPos, "% - 25px)");
            }
            else {
                slider.value = "".concat(lastSliderPos_1);
            }
        });
    }
};
window.addEventListener('load', function (event) { return __awaiter(void 0, void 0, void 0, function () {
    function animate() {
        // request another frame
        rafRef = requestAnimationFrame(animate);
        // calc elapsed time since last loop
        now = Date.now();
        elapsed = now - then;
        // if enough time has elapsed, draw the next frame
        if (elapsed > fpsInterval) {
            // Get ready for next frame by setting then=now, but also adjust for your
            // specified fpsInterval not being a multiple of RAF's interval (16.7ms)
            then = now - (elapsed % fpsInterval);
            if (next > frameCount) {
                cancelAnimationFrame(rafRef);
                isPlaying = false;
            }
            else {
                updateImage(next);
                ++next;
            }
        }
    }
    function startAnimating(fps) {
        isPlaying = true;
        fpsInterval = 1000 / fps;
        then = Date.now();
        startTime = then;
        animate();
    }
    var preloadResults, failedImageLoads, imageCard, updateImage, next, isPlaying, then, rafRef, fpsInterval, now, elapsed, startTime, ticking;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                initSlider('ludo-builders-slider');
                return [4 /*yield*/, preloadImagesPromise(imageUrls)];
            case 1:
                preloadResults = _a.sent();
                failedImageLoads = preloadResults.filter(function (result) { return result.status === 'rejected'; }).map(function (result) { return result.reason; });
                if (!failedImageLoads.length) return [3 /*break*/, 3];
                return [4 /*yield*/, preloadImagesPromise(failedImageLoads)];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3:
                imageCard = document.getElementById('hero-card-img');
                imageCard.src = currentFrame(0);
                updateImage = function (index) {
                    imageCard.src = currentFrame(index);
                };
                next = 0;
                isPlaying = false;
                startAnimating(30);
                ticking = false;
                document.addEventListener('scroll', function (event) {
                    if (isPlaying)
                        return;
                    var scrollTop = window.scrollY;
                    var maxScrollTop = window.innerHeight * 2;
                    var scrollFraction = scrollTop / maxScrollTop;
                    var frameIndex = Math.min(frameCount - 1, Math.ceil(scrollFraction * frameCount));
                    if (!ticking) {
                        requestAnimationFrame(function () {
                            updateImage(frameIndex + 1);
                            ticking = false;
                        });
                        ticking = true;
                    }
                });
                return [2 /*return*/];
        }
    });
}); });
window.addEventListener('DOMContentLoaded', function () {
    var url;
    var _a = authCookies.getCookies(), userId = _a.userId, wallets = _a.wallets, newUser = _a.newUser, inviteCode = _a.inviteCode, authToken = _a.authToken, refreshToken = _a.refreshToken, role = _a.role;
    if (authToken &&
        refreshToken &&
        userId &&
        wallets &&
        newUser &&
        inviteCode &&
        role) {
        url = "".concat(ludoDomains[process.env.ENV_VALUE]['profile'], "/").concat(userId, "/nfts");
    }
    else {
        url = "".concat(ludoDomains[process.env.ENV_VALUE]['auth'], "/login");
    }
    var nftMintingUrl = "".concat(ludoDomains[process.env.ENV_VALUE]['app'], "/minting");
    var countUpElements = document.querySelectorAll("[id*=\"".concat(COUNT_UP_PREFIX, "\"]"));
    countUpElements.forEach(function (element) { return countUpObserver.observe(element); });
    var animationElements = document.querySelectorAll("[class*=\"".concat(ANIMATION_PREFIX, "\"]"));
    animationElements.forEach(function (element) { return observer.observe(element); });
    var ludoNftLink = document.getElementById('ludoNftLink');
    ludoNftLink.href = nftMintingUrl;
    // initializeButtonReact({id:  'ludoTelegramAppBtn', url: 'https://t.me/LudoAppBot', label: 'Open Ludo Telegram App' })
    // initializeButtonReact({id:  'installLudoX', url:'https://chromewebstore.google.com/detail/ludo-x/fgcmebogmgdheonhhefnblfodobpmfip', label: 'Install Ludo X' })
    // initializeButtonReact({id:  'goToApp', url, label: 'Go to App', openInNewTab: false })
    initializeMintButtonReact({ url: nftMintingUrl });
    initializeBurgerReact();
    // initializeMainButtonReact({ url });
    initializeEnvTitleReact();
    initializeAlertReact();
    // initializeApiButtonReact({ url: 'https://apidocs.ludo.com/reference' });
    initializeLudoCuratorButtonReact({ url: nftMintingUrl });
    // markerSDK.loadWidget({
    //   project: '65f2e310e9298c9dca4d4ca9',
    //   useNativeScreenshot: true,
    //   silent: true,
    // });
});
