import { jsx as _jsx } from "react/jsx-runtime";
import ReactDOM from "react-dom/client";
import { useEffect, useState } from "react";
import AlertUi from '@ludo.ninja/ui/build/system/Alert';
import { alertVariants } from "@ludo.ninja/ui/build/system/Alert/type";
var AlertInit = function () {
    var _a = useState(null), alertType = _a[0], setAlertType = _a[1];
    var closeAlert = function () {
        setAlertType(null);
    };
    useEffect(function () {
        var urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('alertError')) {
            var alertError = urlParams.get('alertError');
            alertError = alertError.trim();
            //?alertError=authServerError
            if (alertError === 'authServerError') {
                setAlertType({
                    type: alertVariants.warning,
                    caption: 'Oops...',
                    text: "Your session has expired. Try to log in again"
                });
            }
            //?alertError=serverError
            if (alertError === 'serverError') {
                setAlertType({
                    type: alertVariants.error,
                    caption: 'Oops, server error.',
                    text: ""
                });
            }
            urlParams.delete('alertError');
            var newUrl = "".concat(window.location.pathname).concat(urlParams ? "?".concat(urlParams.toString()) : '');
            window.history.replaceState({}, '', newUrl);
        }
    }, []);
    if (!alertType)
        return null;
    return _jsx(AlertUi, { closeAlert: closeAlert, updateData: alertType });
};
export var initializeAlertReact = function () {
    var rootElement = document.getElementById('alert');
    var root = ReactDOM.createRoot(rootElement);
    root.render(_jsx(AlertInit, {}));
};
